import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandVolumeRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          type: 'nodata',
          subType: 'ana-error'
        }]
      };
      return;
    }

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    if (!total && !prevTotal) {
      this.data = {
        chartData: [{
          type: 'nodata',
          subType: 'default'
        }]
      };
      return;
    }

    const combine = data.map((el) => {
      return {
        ...el,
        prevCount: 0
      };
    });

    prevData.forEach((el) => {
      const target = combine.find((item) => item.id === el.id);
      if (target) {
        target.prevCount = el.count;
      }
    });

    combine.sort((a, b) => b.count - a.count);

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;

    const prototype = [
      ...(
        total
          ? [{
            date: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`
          }]
          : []
      ),
      ...(
        prevTotal
          ? [{
            date: `${previousDate[0].format('YYYY-MM-DD')} ~ ${previousDate[1].format('YYYY-MM-DD')}`
          }]
          : [])
    ];

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < combine.length; i += 1) {
        if (di === 0) {
          row[`${combine[i].name}`] = combine[i].count;
        } else {
          row[`${combine[i].name}`] = combine[i].prevCount;
        }
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_ranking',
          name: '品牌聲量排行',
          data: [{
            labels: combine.slice(0, 10).map((el) => el.name),
            datasets: [
              ...(
                total
                  ? [{
                    label: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`,
                    brandId: combine.slice(0, 10).map((el) => el.id),
                    data: combine.slice(0, 10).map((el) => el.count),
                    backgroundColor: COLOR_SET[0],
                    borderSkipped: true,
                    categoryPercentage: 0.2
                  }]
                  : []
              ),
              ...(
                prevTotal
                  ? [{
                    label: `${previousDate[0].format('YYYY-MM-DD')} ~ ${previousDate[1].format('YYYY-MM-DD')}`,
                    brandId: combine.slice(0, 10).map((el) => el.id),
                    data: combine.slice(0, 10).map((el) => el.prevCount),
                    backgroundColor: COLOR_SET[19],
                    borderSkipped: true,
                    categoryPercentage: 0.2
                  }]
                  : []
              )
            ]
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const brandId = chart.data.datasets[datasetIndex].brandId[index];
              const brandName = chart.data.labels[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/聲量分析/品牌聲量排行'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                grace: '15%',
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new BrandVolumeRankingModel(data, prevData, query);
  }
}
