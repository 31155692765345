import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateGroupColor, generateCSVKey } from 'src/utils/chart';

export default class BrandVolumeProportionModel {
  constructor(origin, prevOrigin, query) {
    const data = origin.slice(0, 10);
    const prevData = prevOrigin.slice(0, 10);

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const combine = data.map((el) => ({
      name: el.name,
      count: el.count,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((item) => item.name === el.name);
      if (target) {
        target.prevCount = el.count;
      } else {
        combine.push({
          name: el.name,
          count: 0,
          prevCount: el.count
        });
      }
    });

    combine.sort((a, b) => b.count - a.count);

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;

    const prototype = [
      {
        date: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`
      },
      ...(
        prevTotal
          ? [{
            date: `${previousDate[0].format('YYYY-MM-DD')} ~ ${previousDate[1].format('YYYY-MM-DD')}`
          }]
          : []
      )
    ];

    const allTotal = (() => {
      let sum = 0;
      origin.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const allPrevTotal = (() => {
      let sum = 0;
      prevOrigin.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const allCombine = origin.map((el) => ({
      name: el.name,
      count: el.count,
      prevCount: 0
    }));

    prevOrigin.forEach((el) => {
      const target = allCombine.find((item) => item.name === el.name);
      if (target) {
        target.prevCount = el.count;
      } else {
        allCombine.push({
          name: el.name,
          count: 0,
          prevCount: el.count
        });
      }
    });

    allCombine.sort((a, b) => b.count - a.count);

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < allCombine.length; i += 1) {
        if (di === 0) {
          row[`${allCombine[i].name}`] = allCombine[i].count;
          row[`${allCombine[i].name}-佔比`] = `${Math.floor((allCombine[i].count / allTotal) * 1000) / 10}%`;
        } else {
          row[`${allCombine[i].name}`] = allCombine[i].prevCount;
          row[`${allCombine[i].name}-佔比`] = `${Math.floor((allCombine[i].prevCount / allPrevTotal) * 1000) / 10}%`;
        }
      }

      return row;
    });

    const color = generateGroupColor(data, prevData);
    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [{
                    label: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`,
                    brandId: data.map((el) => el.id),
                    data: data.map((el) => el.count),
                    backgroundColor: data.map((el) => color[el.id]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: data.length > 0 ? 'pie' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: prevData.map((el) => el.name),
                  datasets: [{
                    label: `${previousDate[0].format('YYYY-MM-DD')} ~ ${previousDate[1].format('YYYY-MM-DD')}`,
                    brandId: prevData.map((el) => el.id),
                    data: prevData.map((el) => el.count),
                    backgroundColor: prevData.map((el) => color[el.id]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData.length > 0 ? 'pie' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const brandName = chart.data.labels[index];
              const brandId = chart.data.datasets[datasetIndex].brandId[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/聲量分析/品牌聲量佔比'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new BrandVolumeProportionModel(data, prevData, query);
  }
}
